import React, { memo, forwardRef, useState } from 'react';
import { FaRegEyeSlash, FaRegEye } from 'react-icons/fa'
import { IoLockClosedOutline, IoMailOutline, IoPersonOutline, IoPhonePortraitOutline } from 'react-icons/io5';
import cn from 'classnames'

import styles from './form-input.module.scss'

const getIcons = (variant = 'email') => ({
	email: IoMailOutline,
	password: IoLockClosedOutline,
	name: IoPersonOutline,
	phone: IoPhonePortraitOutline,
}[variant])

const getBorderRadius = (setBradius = 'none') => ({
	top: styles.topRadius,
	bottom: styles.bottomRadius,
	none: styles.noneRadius,
	both: styles.radius,
}[setBradius])

const getTheme = (theme = 'light') => ({
	light: styles.light,
	dark: styles.dark,
}[theme])

const getTogglePasswordIcon = status => {
	if (status) return FaRegEyeSlash

	return FaRegEye
}

const resolveTogglePasswordType = status => {
	if (status) return 'text'

	return 'password'
}

const Input = forwardRef(({ id, label, variant, className, setBradius, theme, error, type, ...rest }, ref) => {
	const [togglePassword, setTogglePassword] = useState(false)
	const [passwordType, setPasswordType] = useState(type)
	const Icon = getIcons(variant)
	const borderRadiusStyles = getBorderRadius(setBradius)
	const containerBgStyles = getTheme(theme)

	const handleTogglePassword = () => {
		setTogglePassword(status => !status)
		setPasswordType(resolveTogglePasswordType(!togglePassword))
	}

	const TogglePasswordIcon = getTogglePasswordIcon(togglePassword)

	return (
		<div className={cn(styles.container, className, borderRadiusStyles, containerBgStyles)}>
			<Icon className={cn(styles.icon, error && styles.error)} />
			<div className={styles.field}>
				<input ref={ref} id={id} name={id} {...rest} type={passwordType} placeholder={' '} />
				<label htmlFor={id}>{label}</label>
				{type === 'password' && (
					<TogglePasswordIcon className={cn(styles.icon, styles.eye)} onClick={handleTogglePassword} />
				)}
				<p className={styles.errorText}>{error?.message}</p>
			</div>
		</div>
	)
})

export const FormInput = memo(Input)
