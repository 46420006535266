import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { NavigationBar } from './components/navigation/navigation-bar'

import { Layout } from './components/layout/layout'
import { Footer } from './components/footer/footer'
import { ROUTES, GUARD_TYPES, GUARD_ROUTES } from './components/routes/constants'
import { ScrollToTop } from './components/scroll-to-top/scroll-to-top'
import { Guard } from './components/routes/guard'

import styles from './app.module.scss'

function App() {
	return (
		<Layout>
			<NavigationBar/>
			<div className={styles.routes}>
				<ScrollToTop>
					<Routes>
						{Object.keys(ROUTES).map((key) => {
							if (GUARD_ROUTES.includes(key)) {
								return (
									<Route key={key} element={<Guard type={GUARD_TYPES[key]} />}>
										<Route path={`/${key}`} element={ROUTES[key].component} />
									</Route>
								)
							}

							return (
								<Route
									key={key}
									index={ROUTES[key].index}
									path={`/${key}`}
									element={ROUTES[key].component}
								/>
							)
						})}
					</Routes>
				</ScrollToTop>
			</div>
			<Footer/>
		</Layout>
	)
}

export default App
