import React from 'react'
import cn from 'classnames'
import styles from './button.module.scss'

const getVariant = (variant = 'primary') => ({
	primary: styles.primary,
	inverted: styles.inverted,
	disabled: styles.disabled,
}[variant]);

export const Button = (props) => {
	const buttonStyles = getVariant(props.variant)

	return (
		<button {...props} className={cn(styles.container, buttonStyles)}>
			{props.label}
		</button>
	)
}
