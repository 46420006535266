const omitObjectProperties = (obj, properties) => {
	if (typeof obj !== 'object') return obj

	if (!Array.isArray(properties)) return obj

	const snapshot = { ...obj }
	properties.map(prop => delete snapshot[prop])

	return snapshot
}

export default omitObjectProperties
