import { ROUTE_PATH } from '../routes/constants'

export const FOOTERS = [
	{
		category: 'news',
		label: 'THÔNG TIN',
		data: {
			'tin-tuc': 'Tin Tức',
			'bat-dong-san-da-xem': 'Bất Động Sản Đã Xem',
			'bat-dong-san-moi': 'Bất Động Sản Mới',
			[ROUTE_PATH.intro]: 'Giới Thiệu',
			'chinh-sach': 'Chính Sách & Điều Khoản',
		},
	},
	{
		category: 'account',
		label: 'TÀI KHOẢN',
		data: {
			[ROUTE_PATH.signin]: 'Đăng Nhập',
			[ROUTE_PATH.signup]: 'Đăng Ký',
		},
	},
	{
		category: 'contact',
		label: 'LIÊN HỆ',
		data: {
			'lien-he-email': 'Email: bds@lylands.com',
			'lien-he-phone': 'SĐT: 028 3383 4832',
		},
		type: 'text',
	},
];

export const AUTH_FOOTER = {
	category: 'account',
	label: 'TÀI KHOẢN',
	data: {
		[ROUTE_PATH.profile]: 'Thông Tin Tài Khoản',
	},
}

