import React from 'react'
import AvatarIcon from '../../assets/avatar.png'
import cn from 'classnames'
import styles from './avatar.module.scss'

const getAvatarSize = size => ({
	s: styles.small,
	m: styles.medium,
	l: styles.large,
}[size])

export const Avatar = ({ url, size }) => {
	const resolveSource = url ? url : AvatarIcon
	const avatarStyles = getAvatarSize(size)

	return (
		<img src={resolveSource} alt="LyLands Avatar" className={cn(styles.image, avatarStyles)} />
	)
}
