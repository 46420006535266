import { db } from './config'
import { getDocs, collection, query, where } from 'firebase/firestore'
import { FIRESTORE_COLLECTIONS } from '../constants/filestore-collections'

export const getIsPhoneNumberExisting = async number => {
	let existingNumber = false;
	const usersRef = collection(db, FIRESTORE_COLLECTIONS.USERS);
	const q = query(usersRef, where('phoneNumber', '!=', null))
	const querySnapshot = await getDocs(q)

	querySnapshot.forEach(doc => {
		console.log(doc.id, ' ==> ', doc.data())
		const { phoneNumber } = doc.data()
		/*
		 * Return false when input number is existing.
		 * Return true when input number is new.
		 */
		if (phoneNumber === number) existingNumber = true
	})

	return existingNumber
}
