import React, { useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticationLayout } from '../layout/authentication-layout'
import { useForm } from 'react-hook-form'
import { FormInput } from '../form-input/form-input'
import { Button } from '../button/button'
import { ROUTE_PATH } from '../routes/constants'
import { useDispatch } from 'react-redux'
import AuthForm, { FORM_ID } from '../../constants/auth-form'
import { sendRequestResetPasswordEmail } from '../../firebase/resetAuthUserPassword'
import { setLoading, setError } from '../../redux/slices/system.slice'
import styles from './forgot-password.module.scss'

const EMAIL_FIELD = { ...AuthForm.fields[FORM_ID.userEmail], id: FORM_ID.userEmail }
const inputProps = { ...EMAIL_FIELD };
delete inputProps.criteria

const contents = {
	page: {
		title: 'Quên mật khẩu ...',
		shortDescription: 'Hãy nhập lại địa chỉ email, một đường link hướng dẫn thay đổi mật khẩu sẽ được gởi đến email này',
		code: 201,
	},
	200: {
		title: 'Yêu cầu đã hoàn tất!',
		shortDescription: 'Một đường link hướng dẫn đặt lại mật khẩu đã được gởi đến Email cá nhân. Hãy kiểm tra hộp thư và xác nhận đổi mật khẩu. (Vui lòng kiểm tra mục "spam" nếu không tìm thấy email).',
		code: 200,
	},
	400: {
		title: 'Email không tồn tại ...',
		shortDescription: 'Hãy nhập một địa chỉ email đã được xác thực trên Lylands.com',
		code: 400,
	},
	500: {
		title: 'Đã xảy ra lỗi ...',
		shortDescription: 'Hệ thống hiện không phản hồi, vui lòng thử lại trong giây lát',
		code: 500,
	}
}

const initState = {
	title: contents.page.title,
	shortDescription: contents.page.shortDescription,
	code: contents.page.code,
}

const reducer = (state, action) => {
	switch (action.type) {
		case 'title':
			return contents.title
		case '200':
			return contents[200]
		case '400':
			return contents[400]
		case '500':
			return contents[500]
		default:
			return state
	}
}

export const ForgotPassword = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [content, reactDispatch] = useReducer(reducer, initState);
	const { register, handleSubmit, formState: { errors, isValid, isDirty }} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange'
	})

	const onSubmit = async data => {
		const { userEmail } = data
		try {
			dispatch(setLoading(true))
			await sendRequestResetPasswordEmail(userEmail)
			reactDispatch({ type: '200' })
		} catch (error) {
			if (error.code === 'auth/user-not-found') {
				reactDispatch({ type: '400' })
				dispatch(setError('Địa chỉ email này không tồn tại trên hệ thống. Vui lòng nhập một địa chỉ xác thực.'))
			} else if (error.code === 'auth/network-request-failed') {
				dispatch(setError('Không có kết nối internet. Vui lòng kiểm tra lại đường truyền tín hiệu.'))

			} else {
				reactDispatch({ type: '500' })
				console.log('error.code', error.code)
				dispatch(setError('Hệ thống đang bảo trì. Vui lòng thử lại sau!'))
			}
		} finally {
			dispatch(setLoading(false))
		}
	}

	const goSignIn = () => {
		navigate(`/${ROUTE_PATH.signin}`)
	}

	const submissionDisabled = !isDirty || !isValid;

	return (
		<AuthenticationLayout
			title={content.title}
			shortDescription={content.shortDescription}
			operationType={AuthForm.operationType.forgotPwd}
		>
			{[500, 400, 201].includes(content.code) && (
				<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
					<FormInput
						{...inputProps}
						{...register(inputProps.id, { ...EMAIL_FIELD.criteria })}
						error={errors[inputProps.id]}
						setBradius="both"
					/>
					<div className={styles.actions}>
						<Button
							label="THAY ĐỔI MẬT KHẨU"
							type="submit"
							variant={submissionDisabled ? 'disabled' : 'primary'}
							disabled={submissionDisabled}
						/>
						<Button
							label="ĐĂNG NHẬP"
							type="button"
							variant="inverted"
							onClick={goSignIn}
						/>
					</div>
				</form>
			)}
			{content.code === 200 && (
				<div className={styles.reLogin}>
					<Button
						label="ĐĂNG NHẬP"
						type="button"
						onClick={goSignIn}
					/>
				</div>
			)}
		</AuthenticationLayout>
	)
}
