import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	loading: false,
	error: '',
}

const systemSlice = createSlice({
	name: 'system',
	initialState,
	reducers: {
		setLoading(state, action) {
			state.loading = action.payload
		},
		setError(state, action) {
			state.error = action.payload
		}
	}
})

export const { setLoading, setError } = systemSlice.actions
export default systemSlice.reducer
