import React, { useState, useEffect } from 'react';
import { FOOTERS, AUTH_FOOTER } from './constants';
import { InfoBlock } from './info-block';
import { useSelector } from 'react-redux'
import Logo from '../../assets/logo-white.png'
import styles from './footer.module.scss';

export const Footer = () => {
	const { user } = useSelector(state => state.authentication)
	const [footerItems, setFooterItems] = useState([...FOOTERS])

	useEffect(() => {
		const authFooter = [...FOOTERS]
		if (user?.uid) {
			authFooter.splice(1, 1, AUTH_FOOTER)
			setFooterItems(authFooter)
		} else {
			setFooterItems([...FOOTERS])
		}
	}, [user])

	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<div className={styles.heading}>
					{footerItems.map(item => (
						<InfoBlock key={item.label} {...item} />
					))}
					<div className={styles.brand}>
						<img src={Logo} className={styles.logo} alt="Logo Footer"/>
					</div>
				</div>
				<div className={styles.copyright}>
					&copy; {new Date().getFullYear()} Bản quyền thuộc về Công Ty TNHH MTV LyLands
				</div>
			</div>
		</div>
	);
};
