import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { auth } from './config'
import { getUserSnapshot } from './utils'

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
	if (!email || !password) return null;

	const { user: { uid, accessToken } } = await signInWithEmailAndPassword(auth, email, password)

	const { data } = await getUserSnapshot(uid)
	delete data.createdAt

	return { ...data, uid, accessToken }
}

export const signOutAuthUser = async () => signOut(auth)
