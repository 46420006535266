import React from 'react'
import Facebook from '../../assets/facebook.png'
import Google from '../../assets/google.png'
import { FORM_ID, USER_TYPE } from '../../constants/auth-form'
import { createUsersDocument } from '../../firebase/createUsersDocument'
import { useDispatch } from 'react-redux'
import { signInWithGooglePopUp } from '../../firebase/googleSignIn'
import { setError, setLoading } from '../../redux/slices/system.slice'
import { setUser } from '../../redux/slices/user.slice'
import pickObjectProperties from '../../utils/pickObjectProperties'

import styles from './social-auth.module.scss'

export const SocialAuth = () => {
	const dispatch = useDispatch()

	const handleGoogleSignIn = async () => {
		try {
			dispatch(setLoading(true))
			const { user } = await signInWithGooglePopUp()
			const { accessToken } = user
			const additionalInfo = pickObjectProperties(
				user,
				[FORM_ID.userName, FORM_ID.userPhone, FORM_ID.userPhotoURL],
			)

			additionalInfo[FORM_ID.userType] = USER_TYPE.client
			const userRecord = await createUsersDocument(user.uid, user.email, additionalInfo)

			if (userRecord) {
				dispatch(setUser({ uid: user.uid, ...userRecord, accessToken }))
			} else {
				dispatch(setUser({ uid: user.uid, email: user.email, accessToken, ...additionalInfo }))
			}

		} catch (error) {
			dispatch(setError('Không thể đăng nhập với tài khoản Google. Vui lòng thử lại.'))
		} finally {
			dispatch(setLoading(false))
		}
	}
	return (
		<div className={styles.container}>
			<p className={styles.caption}>Hoặc sử dụng tài khoản sau</p>
			<div className={styles.socials}>
				<div role="button" className={styles.logInPill} onClick={handleGoogleSignIn}>
					<img src={Google} className={styles.image} alt="Google SignIn"/>
				</div>
				<div role="button" className={styles.logInPill}>
					<img src={Facebook} className={styles.image} alt="Google SignIn"/>
				</div>
			</div>
		</div>
	)
}
