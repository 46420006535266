import React from 'react'
import styles from './page-layout.module.scss'

export const PageLayout = ({ children }) => {
	return (
		<div className={styles.container}>
			{children}
		</div>
	)
}
