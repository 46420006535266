export const FORM_ID = {
	userEmail: 'email',
	userPassword: 'userPassword',
	confirmPassword: 'confirmPassword',
	userName: 'displayName',
	userPhotoURL: 'photoURL',
	userPhone: 'phoneNumber',
	userType: 'userType',
}

export const USER_TYPE = {
	client: 'client',
	staff: 'staff',
}

export const USER_LABEL_TYPE = {
	client: 'Thông thường',
	staff: 'Đăng tin BĐS',
}

export const REG_TYPE = [
	{
		id: USER_TYPE.client,
		name: FORM_ID.userType,
		label: 'Thông thường',
	},
	{
		id: USER_TYPE.staff,
		name: FORM_ID.userType,
		label: 'Đăng tin BĐS',
	},
]

const authForm = {
	title: {
		signUp: 'Đăng ký tài khoản ...',
		signIn: 'Hi! Xin chào ...',
		resetPwd: 'Cài đặt lại mật khẩu ...'
	},
	shortDescription: {
		signUp: 'Chào mừng đến với LyLands, hãy điền đầy đủ thông tin để tạo một tài khoản thành viên',
		signIn: 'Để cập nhật thông tin BĐS mới nhất từ LyLands, vui lòng đăng nhập tài khoản email và mật khẩu cá nhân',
		resetPwd: 'Yêu cầu thay đổi mật khẩu của tài khoản đã được kích hoạt, hãy nhập một mật khẩu bảo mật và tiếp tục',
	},
	operationType: {
		signUp: 'signUp',
		signIn: 'signIn',
		forgotPwd: 'forgotPwd',
		resetPwd: 'resetPwd',
	},
	fields: {
		[FORM_ID.userName]: {
			label: 'Họ tên',
			variant: 'name',
			setBradius: 'top',
			criteria: {
				pattern: {
					value: /^\S[\w\s]{4,40}\S$/,
					message: 'Có từ 5-40 ký tự (không bao gồm khoảng trắng)'
				},
				required: true,
			},
			theme: 'dark'
		},
		[FORM_ID.userEmail]: {
			label: 'Email',
			type: 'email',
			variant: 'email',
			criteria: {
				pattern: {
					value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/g,
					message: 'Email chưa đúng định dạng.',
				},
				required: true,
			},
		},
		[FORM_ID.userPassword]: {
			label: 'Mật khẩu',
			type: 'password',
			variant: 'password',
			criteria: {
				pattern: {
					value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}$/,
					message: 'Ít nhất có 8 ký tự (in hoa, chữ số, ký tự đặc biệt)',
				},
				required: true,
			},
			theme: 'dark'
		},
		[FORM_ID.confirmPassword]: {
			label: 'Xác nhận mật khẩu',
			type: 'password',
			variant: 'password',
			criteria: {
				deps: [FORM_ID.userPassword],
				required: true,
			},
		},
	},
}

export default authForm
