import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import { useSelector } from 'react-redux'

import { Avatar } from '../avatar/avatar'
import Logo from '../../assets/logo-white-h.png'
import { ROUTES, ROUTE_PATH } from '../routes/constants'

import styles from './navigation-bar.module.scss'

export const NavigationBar = () => {
	const { user } = useSelector(state => state.authentication)
	const [authUser, setAuthUser] = useState(null)

	useEffect(() => {
		setAuthUser({ ...user })
	}, [user])

	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<NavLink to={ROUTE_PATH.intro} className={styles.logo}>
					<img src={Logo} alt="Logo Navigation" className={styles.image} />
				</NavLink>
				<div className={styles.links}>
					{Object.keys(ROUTES).map(key => {
						if (authUser?.uid && key === ROUTE_PATH.signin) {

							return (
								<NavLink key={key} to={ROUTE_PATH.profile} className={styles.link}>
									<Avatar url={authUser.photoURL} size="s" />
								</NavLink>
							)
						}

						if (!ROUTES[key].hideNavBar) {
							return (
								<NavLink key={key} to={key} className={
									({ isActive }) => cn(styles.link, isActive && styles.activeLink)
								}>
									<span
										className={cn(styles.icon, key === '/' && styles.homeIcon)}>{ROUTES[key].icon}
									</span>
									<span className={styles.label}>{ROUTES[key].label}</span>
								</NavLink>
							)
						}

						return null;
					})}
				</div>
			</div>
		</div>
	);
}
