import React, { useState, useRef } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { createUsersDocument } from '../../firebase/createUsersDocument'
import isEmpty from '../../utils/isEmpty'
import omitObjectProperties from '../../utils/omitObjectProperties'
import { FormRadio } from '../form-radio/form-radio'
import { FormInput } from '../form-input/form-input'
import { useForm } from 'react-hook-form'
import { Button } from '../button/button'
import { AuthenticationLayout } from '../layout/authentication-layout'
import { useDispatch } from 'react-redux'
import { setUser } from '../../redux/slices/user.slice'
import { setError, setLoading } from '../../redux/slices/system.slice'
import { ROUTE_PATH } from '../routes/constants'
import AuthForm, { FORM_ID, USER_TYPE, REG_TYPE } from '../../constants/auth-form.js'
import {
	createAuthUserWithEmailAndPassword
} from '../../firebase/createUserWithEmailAndPassword'
import { getIsPhoneNumberExisting } from '../../firebase/getIsPhoneNumberExisting'
import { SocialAuth } from '../social-auth/social-auth'

import styles from './sign-up.module.scss'

const SIGN_UP_FIELDS = Object.keys(AuthForm.fields).map(key => ( {
	...AuthForm.fields[key],
	id: key
} ))
const PAGE_TITLE = AuthForm.title.signUp
const PAGE_DESC = AuthForm.shortDescription.signUp
const OPERATION_TYPE = AuthForm.operationType.signUp

export const SignUp = () => {
	const navigate = useNavigate()
	const [regType, setRegType] = useState(USER_TYPE.client)
	const {
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid },
		watch,
		setError: formSetError,
		clearErrors,
		resetField,
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: { [FORM_ID.userPhone]: null }
	})
	const isStaff = regType === USER_TYPE.staff
	const password = useRef({})
	password.current = watch(FORM_ID.userPassword, '')
	const dispatch = useDispatch()

	const handleChangeRegType = evt => {
		setRegType(evt.target.value)
		if (evt.target.value === USER_TYPE.staff) {
			formSetError(FORM_ID.userPhone, { type: 'focus'}, { shouldFocus: true })
		} else {
			clearErrors(FORM_ID.userPhone)
			resetField(FORM_ID.userPhone)
		}
	}

	const goSignInUser = () => {
		navigate(`/${ROUTE_PATH.signin}`)
	}

	const onSubmit = async data => {
		try {
			dispatch(setLoading(true))

			const isPhoneNumberExisting = await getIsPhoneNumberExisting(data[FORM_ID.userPhone])
			if (isPhoneNumberExisting) {
				dispatch(setError('Số điện thoại này đã được sử dụng. Vui lòng chọn số khác.'))
				return
			}

			const {
				user: {
					uid, email, accessToken,
				}
			} = await createAuthUserWithEmailAndPassword(data[FORM_ID.userEmail], data[FORM_ID.userPassword])

			if (uid) {
				try {
					const additionalInfo = omitObjectProperties(data, [
						FORM_ID.userEmail,
						FORM_ID.userPassword,
						FORM_ID.confirmPassword,
					])
					await createUsersDocument(uid, email, additionalInfo)
					dispatch(setUser({ uid, email, ...additionalInfo, accessToken }))

				} catch (error) {
					dispatch(setError('Hệ thông chưa thể khởi tạo tài khoản trên Lylands.com. Bạn vẫn có thể đăng nhập với tư cách người dùng thông thường.'))
				}
			}
		} catch (error) {
			if (error.code === 'auth/email-already-in-use') {
				dispatch(setError('Email đã được xác thực trên hệ thống. Hãy đăng ký một email khác.'))
			}
		} finally {
			dispatch(setLoading(false))
		}
	}

	const submissionDisabled = !isDirty || !isEmpty(errors) || !isValid

	return ( <AuthenticationLayout
		title={PAGE_TITLE}
		shortDescription={PAGE_DESC}
		operationType={OPERATION_TYPE}
	>
		<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
			{SIGN_UP_FIELDS.map(input => {
				const props = { ...input }
				if (props.id === FORM_ID.confirmPassword) {
					props.setBradius = isStaff ? 'none' : 'bottom'
					props.criteria = {
						...props.criteria,
						validate: val => val === password.current || 'Mật khẩu không giống nhau.'
					}
				}

				if (!props.criteria) {
					props.criteria = {}
				}

				return ( <FormInput
					key={props.id} {...props} {...register(props.id, { ...props.criteria })}
					error={errors[props.id]}
				/> )
			})}
			<div className={cn(styles.staffPhone, { [styles.hide]: !isStaff })}>
				<FormInput
					id={FORM_ID.userPhone}
					label="Số điện thoại"
					setBradius="bottom"
					variant="phone"
					theme="dark"
					{...register(FORM_ID.userPhone, {
						pattern: {
							value: /^\d{10}$/, message: 'Số điện thoại chưa đúng định dạng.',
						},
						required: isStaff,
					})}
					error={errors[FORM_ID.userPhone]}
				/>
			</div>
			<div className={styles.regTypes}>
				<p className={styles.caption}>Loại tài khoản</p>
				<div className={styles.radios}>
					{REG_TYPE.map(option => ( <FormRadio
						key={option.id}
						{...register(FORM_ID.userType)}
						{...option}
						isChecked={regType === option.id}
						value={option.id}
						onChange={handleChangeRegType}
					/> ))}
				</div>
			</div>
			<div className={styles.actions}>
				<Button
					type="submit"
					label="TẠO TÀI KHOẢN"
	        variant={submissionDisabled ? 'disabled' : 'primary'}
	        disabled={submissionDisabled}
				/>
				<Button
					label="ĐÃ CÓ TÀI KHOẢN"
					type="button"
					variant="inverted"
					onClick={goSignInUser}
				/>
			</div>
			<SocialAuth />
		</form>
	</AuthenticationLayout> )
}
