import React from 'react';
import { MdHome } from 'react-icons/md';
import { BiUserCircle } from 'react-icons/bi';
import { GoInfo } from 'react-icons/go';
import { About } from '../about/about';
import { SignIn } from '../sign-in/sign-in';
import { SignUp } from '../sign-up/sign-up';
import { ForgotPassword } from '../forgot-password/forgot-password'
import { Profile } from '../profile/profile'
import { ResetPassword } from '../resetPassword/reset-password'

export const ROUTE_PATH = {
	home: '/',
	intro: 'gioi-thieu',
	signin: 'tai-khoan/dang-nhap',
	signup: 'tai-khoan/dang-ky',
	forgotpw: 'tai-khoan/quen-mat-khau',
	profile: 'tai-khoan/thong-tin-tai-khoan',
	resetpw: 'tai-khoan/thiet-lap-mat-khau-moi',
}

export const ROUTES = {
	[ROUTE_PATH.home]: {
		label: '',
		component: <div>We are coming soon</div>,
		icon: <MdHome />,
		index: true,
	},
	[ROUTE_PATH.intro]: {
		label: 'Giới Thiệu',
		component: <About />,
		icon: <GoInfo />,
	},
	[ROUTE_PATH.signin]: {
		label: 'Đăng Nhập',
		component: <SignIn />,
		icon: <BiUserCircle />,
	},
	[ROUTE_PATH.signup]: {
		component: <SignUp />,
		hideNavBar: true,
	},
	[ROUTE_PATH.forgotpw]: {
		component: <ForgotPassword />,
		hideNavBar: true,
	},
	[ROUTE_PATH.profile]: {
		component: <Profile />,
		hideNavBar: true,
	},
	[ROUTE_PATH.resetpw]: {
		component: <ResetPassword />,
		hideNavBar: true,
	}
};

export const GUARD_TYPES = {
	auth: 'auth',
	profile: 'profile',
	[ROUTE_PATH.signup]: 'auth',
	[ROUTE_PATH.signin]: 'auth',
	[ROUTE_PATH.forgotpw]: 'auth',
	[ROUTE_PATH.resetpw]: 'auth',
	[ROUTE_PATH.profile]: 'profile',
}

export const GUARD_ROUTES = [
	ROUTE_PATH.signup,
	ROUTE_PATH.signin,
	ROUTE_PATH.forgotpw,
	ROUTE_PATH.profile,
	ROUTE_PATH.resetpw,
]
