import React, { useState, useEffect, useRef } from 'react'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import { verifyUserPasswordResetCode, confirmUserPasswordReset } from '../../firebase/resetAuthUserPassword'
import { Button } from '../button/button'
import { useForm } from 'react-hook-form'
import { FormInput } from '../form-input/form-input'
import AuthForm, { FORM_ID } from '../../constants/auth-form'
import { AuthenticationLayout } from '../layout/authentication-layout'
import { setError, setLoading } from '../../redux/slices/system.slice'
import { useDispatch } from 'react-redux'
import { ROUTE_PATH } from '../routes/constants'
import styles from './reset-password.module.scss'

const PAGE_TITLE = AuthForm.title.resetPwd
const PAGE_DESC = AuthForm.shortDescription.resetPwd
const OPERATION_TYPE = AuthForm.operationType.resetPwd

const RESET_PASSWORD_FIELDS = [
	{ ...AuthForm.fields[FORM_ID.userPassword], id: FORM_ID.userPassword },
	{ ...AuthForm.fields[FORM_ID.confirmPassword], id: FORM_ID.confirmPassword },
]

export const ResetPassword = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { register, handleSubmit, formState: { errors, isDirty, isValid}, watch } = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange'
	})
	const [searchParams] = useSearchParams()
	const [userEmail, setUserEmail] = useState(null)
	const [pageDesc, setPageDesc] = useState(PAGE_DESC)
	const [requestError, setRequestError] = useState(false)
	const [ooBCode, setOoBCode] = useState('')
	const password = useRef({})
	password.current = watch(FORM_ID.userPassword, '')

	useEffect(() => {

		const onVerifyPwdRstCode = async () => {
			const code = searchParams.get('oobCode')
			setOoBCode(code)

			try {
				dispatch(setLoading(true))
				const verifiedEmail = await verifyUserPasswordResetCode(code)
				setUserEmail(verifiedEmail)
			} catch (error) {
				console.log('error.code', error.code)
				const ERROR_CODES = ['auth/invalid-action-code', 'auth/expired-action-code']
				if (ERROR_CODES.includes(error.code)) {
					setPageDesc('Đường link kích hoạt thay đổi mật khẩu đã hết hạn.')
					dispatch(setError('Đường link kích hoạt đổi mật khẩu đã hết hạn sử dụng. Vui lòng thử lại đường link khác!'))
					setRequestError(true)
				}
			} finally {
				dispatch(setLoading(false))
			}
		}

		onVerifyPwdRstCode().then()

	}, [searchParams, dispatch, setUserEmail])

	const onSubmit = async data => {
		try {
			dispatch(setLoading(true))

			await confirmUserPasswordReset(ooBCode, data.userPassword)
			navigate(`/${ROUTE_PATH.signin}`)

		} catch (error) {
			dispatch(setError('Chưa thể cài đặt mật khẩu mới!'))
		} finally {
			dispatch(setLoading(false))
		}
	}

	const submissionDisabled = !isDirty || !isValid;
	const submissionVariant = submissionDisabled ? 'disabled' : 'primary'

	return (
		<AuthenticationLayout
			title={PAGE_TITLE}
			shortDescription={pageDesc}
			operationType={OPERATION_TYPE}
		>
			{requestError ?
				<Link to={`/${ROUTE_PATH.forgotpw}`} className={styles.link}>
					Yêu cầu đổi mật khẩu
				</Link>
			: (
				<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
					<p className={styles.heading}>{userEmail}</p>
					{RESET_PASSWORD_FIELDS.map(input => {
						const props = { ...input, setBradius: 'top' }

						if (props.id === FORM_ID.confirmPassword) {
							props.setBradius = 'bottom'
							props.criteria = {
								...props.criteria,
								validate: val => val === password.current || 'Mật khẩu không giống nhau.'
							}
						}

						if (!props.criteria) {
							props.criteria = {}
						}

						return (
							<FormInput
								{...props}
								{...register(props.id, { ...props.criteria })}
								error={errors[props.id]}
							/>
						)
					})}
					<div className={styles.action}>
						<Button
							label="Xác Nhận"
							type="submit"
							variant={submissionVariant}
							disabled={submissionDisabled}
						/>
					</div>
				</form>
			)}
		</AuthenticationLayout>
	)
}
