import React, { memo, forwardRef } from 'react'
import styles from './form-radio.module.scss'

const Radio = forwardRef(({ label, id, name, onChange, isChecked, ...rest }, ref) => {
	return (
		<div className={styles.container}>
			<input
				ref={ref}
				id={id}
				type="radio"
        name={name}
				checked={isChecked}
        onChange={onChange}
				{...rest}
			/>
			<label htmlFor={id}>{label}</label>
		</div>
	)
})

export const FormRadio = memo(Radio)
