import React from 'react'
import { useLocation, Outlet, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ROUTE_PATH, GUARD_TYPES } from './constants'


export const Guard = ({ type = GUARD_TYPES.profile }) => {
	const { user } = useSelector(state => state.authentication)
	const location = useLocation()

	const resolveGuard = (type === GUARD_TYPES.profile && user?.uid) || (type === GUARD_TYPES.auth && !user?.uid)

	return resolveGuard ? <Outlet /> : <Navigate to={ROUTE_PATH.home} replace state={{ from: location }} />
}
