import { setDoc } from 'firebase/firestore'
import { getUserSnapshot } from './utils'

export const createUsersDocument = async (uid, email, additionalInfo) => {
	const { data, docRef } = await getUserSnapshot(uid);

	if (!data) {
		const createdAt = new Date()

		try {
			await setDoc(docRef, {
				email,
				createdAt,
				...additionalInfo
			})
		} catch (error) {
			console.error('error creating the user', error.message)
		}
	}

	return data
}
