import React from 'react';
import { Loading } from '../loading/loading'
import { useSelector } from 'react-redux'
import { ErrorModal } from '../error-modal/error-modal'

import styles from './layout.module.scss';

export const Layout = ({ children }) => {
	const { loading, error } = useSelector(state => state.system)

	return (
		<div className={styles.container}>
			{children}
			<Loading loading={loading} />
			<ErrorModal error={error} />
		</div>
	);
};
