import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AuthenticationLayout } from '../layout/authentication-layout'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { FormInput } from '../form-input/form-input'
import { Button } from '../button/button'
import { ROUTE_PATH } from '../routes/constants'
import {
	signInAuthUserWithEmailAndPassword
} from '../../firebase/signInAuthUserWithEmailAndPassword'
import AUTH_FORM, { FORM_ID } from '../../constants/auth-form'
import { setUser } from '../../redux/slices/user.slice'
import { setError, setLoading } from '../../redux/slices/system.slice'
import { SocialAuth } from '../social-auth/social-auth'

import styles from './sign-in.module.scss'

const SIGN_IN_FIELDS = [
	{ ...AUTH_FORM.fields[FORM_ID.userEmail], id: FORM_ID.userEmail },
	{ ...AUTH_FORM.fields[FORM_ID.userPassword], id: FORM_ID.userPassword },
]

const PAGE_TITLE = AUTH_FORM.title.signIn
const PAGE_DESC = AUTH_FORM.shortDescription.signIn
const OPERATION_TYPE = AUTH_FORM.operationType.signIn

export const SignIn = () => {
	const navigate = useNavigate()
	const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange'
	})
	const dispatch = useDispatch()

	const goRegisterAccount = () => {
		navigate(`/${ROUTE_PATH.signup}`)
	}

	const onSubmit = async data => {
		try {
			dispatch(setLoading(true))
			const user = await signInAuthUserWithEmailAndPassword(data[FORM_ID.userEmail], data[FORM_ID.userPassword])
			dispatch(setUser(user))
		} catch (error) {
			if (error.code === 'auth/user-not-found') {
				dispatch(setError('Tài khoản email chưa xác thực hoặc không tồn tại. Vui lòng thử lại.'))
			} else if (error.code === 'auth/wrong-password') {
				dispatch(setError('Mật khẩu đăng nhập không đúng. Vui lòng thử lại.'))
			}
		} finally {
			dispatch(setLoading(false))
		}
	}

	const submissionDisabled = !isDirty || !isValid

	return (
		<AuthenticationLayout
			title={PAGE_TITLE}
			shortDescription={PAGE_DESC}
			operationType={OPERATION_TYPE}
		>
			<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
				{SIGN_IN_FIELDS.map((input, index) => {
					const props = { ...input, setBradius: 'top' }

					if (index === SIGN_IN_FIELDS.length - 1) {
						props.setBradius = 'bottom'
					}

					if (!props.criteria) {
						props.criteria = {}
					}

					return (
						<FormInput
							key={input.id} {...props} {...register(input.id, { ...props.criteria })}
							error={errors[props.id]}/>
					)
				})}
				<div className={styles.forgotPassword}>
					<Link className={styles.forgotPassword} to={`/${ROUTE_PATH.forgotpw}`}>
						Quên mật khẩu?
					</Link>
				</div>
				<div className={styles.actions}>
					<Button
						type="submit"
						label="ĐĂNG NHẬP"
						variant={submissionDisabled ? 'disabled' : 'primary'}
						disabled={submissionDisabled}
					/>
					<Button
						type="button"
						variant="inverted"
						label="ĐĂNG KÝ TÀI KHOẢN"
						onClick={goRegisterAccount}
					/>
				</div>
			</form>
			<SocialAuth />
		</AuthenticationLayout>
	)
}
