import React from 'react'
import cn from 'classnames'
import Thanks from '../../assets/thanks.png'

import styles from './about.module.scss'

export const About = () => {
	return ( <div className={styles.container}>
		<p className={styles.heading}>VỀ CHÚNG TÔI</p>
		<hr className={styles.separator}/>
		<br/>
		<p className={styles.text}>
			<strong>CÔNG TY TNHH TƯ VẤN DỊCH VỤ BẤT ĐỘNG SẢN LYLANDS</strong> là công ty môi
			giới: uy tín, chuyên nghiệp, am hiểu thị trường với đội ngũ nhân viên giàu kinh
			nghiệm, năng động và nhiệt huyết với nghề, công ty đã thực hiện thành công rất
			nhiều hợp đồng mua bán / cho thuê căn hộ / đất nền nhằm đáp ứng nhu cầu về nhà ở
			thực tế / đầu tư của khách hàng, chăm sóc và tư vấn tận tình về những nghi vấn băn
			khoăn trong giao dịch bất động sản.
		</p>

		<p className={styles.text}>
			Có kinh nghiệm nhiều năm trong ngành bất động sản, công ty luôn luôn hỗ trợ khách
			hàng để khách có những giao dịch thành công và thủ tục nhanh chóng.
		</p>

		<p className={styles.text}>
			Với phương châm: <i><strong>“Sự tin tưởng và hài lòng của quý khách hàng là yếu tố
			quyết định dẫn đến sự thành công”</strong></i>, công ty luôn tư vấn cho quý khách
			hàng những thông tin chính xác và đầy đủ nhất.
		</p>
		<p className={styles.text}>
			Tất cả giao dịch được thể hiện trên giấy tờ, hợp đồng minh bạch đảm bảo quyền lợi
			công
			bằng cho hai bên.
		</p>
		<p className={styles.text}>
			Bất cứ khi nào có nhu cầu tìm hiểu và tư vấn về bất động sản, hãy liên hệ với công
			ty chúng tôi để có được những thông tin chính xác và đầy đủ nhất.
		</p>
		<p className={cn(styles.highlight, styles.text)}>
			<strong>VÌ NIỀM VUI VÀ SỰ AN TÂM CỦA QUÝ KHÁCH, CHÚNG TÔI PHỤC VỤ!</strong>
		</p>
		<div className={styles.thanks}>
			<img src={Thanks} alt="Thanks"/>
		</div>
	</div> )
}
