import { auth } from './config'
import { sendPasswordResetEmail, verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth'

export const sendRequestResetPasswordEmail = async email => {
	return sendPasswordResetEmail(auth, email)
}

export const verifyUserPasswordResetCode = async code => {
	return verifyPasswordResetCode(auth, code)
}

export const confirmUserPasswordReset = async (code, password) => {
	return confirmPasswordReset(auth, code, password)
}
