import React from 'react'
import Logo from '../../assets/loading.png'
import styles from './loading.module.scss'

export const Loading = ({ loading }) => {

	return loading ? (
		<div className={styles.container}>
			<img src={Logo} alt="Loading..." className={styles.image} />
		</div>
	) : null
}
