import React, { memo } from 'react'
import { Button } from '../button/button'
import { useDispatch } from 'react-redux'
import { setError } from '../../redux/slices/system.slice'
import styles from './error-modal.module.scss'

const Error = ({ error }) => {
	const dispatch = useDispatch()

	const onCloseError = () => {
		dispatch(setError(''))
	}

	return !!error && (
		<div className={styles.container}>
			<div className={styles.content}>
				<p className={styles.title}>
					LyLands.com - Lỗi khi thao tác!
				</p>
				<p className={styles.message}>
					{error}
				</p>
				<div className={styles.action}>
					<Button label="Đã Hiểu!" onClick={onCloseError} />
				</div>
			</div>
		</div>
	)
}

export const ErrorModal = memo(Error)
