import React from 'react'
import { Avatar } from '../avatar/avatar'
import { ROUTE_PATH } from '../routes/constants'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PageLayout } from '../layout/page-layout'
import { signOutAuthUser } from '../../firebase/signInAuthUserWithEmailAndPassword'
import { signOut } from '../../redux/slices/user.slice'
import { FORM_ID, USER_LABEL_TYPE } from '../../constants/auth-form'
import { Button } from '../button/button'
import styles from './profile.module.scss'

export const Profile = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const { user } = useSelector(state => state.authentication)

	const handleSignOut = async () => {
		await signOutAuthUser()
		dispatch(signOut())
		navigate(ROUTE_PATH.home)
	}

	return (
		<PageLayout>
			<div className={styles.container}>
				<div className={styles.picture}>
					<Avatar url={user.photoURL} size="l" />
					<div className={styles.section}>
						<p className={styles.text}>{user[FORM_ID.userName]}</p>
						<p className={styles.text}>{user[FORM_ID.userEmail]}</p>
					</div>
					<Button onClick={handleSignOut} variant="inverted" label="ĐĂNG XUẤT" />
				</div>

				<div className={styles.info}>
					<p className={styles.heading}>
						Thông tin tài khoản
					</p>
					<div className={styles.details}>
						<p className={styles.group}>
							<span className={styles.label}>Loại tài khoản: </span>
							<span className={styles.data}>{USER_LABEL_TYPE[user[FORM_ID.userType]]}</span>
						</p>
						<p className={styles.group}>
							<span className={styles.label}>Số điện thoại: </span>
							<span className={styles.data}>{user[FORM_ID.userPhone] ?? 'Chưa có thông tin'}</span>
						</p>
						<p className={styles.group}>
							<span className={styles.label}>Số bài đã đăng: </span>
							<span className={styles.data}>1938</span>
						</p>
					</div>

				</div>
			</div>
		</PageLayout>
	)
}
