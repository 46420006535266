import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import userReducer from '../slices/user.slice'
import systemReducer from '../slices/system.slice'

const rootReducer = combineReducers({
	authentication: userReducer,
	system: systemReducer,
})

const persistConfig = {
	key: 'lylands',
	storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const index = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware => getDefaultMiddleware({
		serializableCheck: {
			ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
		}
	})
})

const persistedStore = persistStore(index)

const appStore = () => ({
	store: index,
	persistor: persistedStore
})

export default appStore()
