const pickObjectProperties = (obj, properties) => {
	if (typeof obj !== 'object') return obj

	if (!Array.isArray(properties)) return obj

	const snapshot = {}

	properties.map(prop => snapshot[prop] = obj[prop])

	return snapshot
}

export default pickObjectProperties
