import React from 'react'
import Abstract1 from '../../assets/earth.png'
import Abstract2 from '../../assets/earth-2.png'
import Abstract3 from '../../assets/forgot-password.png'
import Abstract4 from '../../assets/change-password.png'
import AuthForm from '../../constants/auth-form'
import styles from './authentication-layout.module.scss'

const OPERATION_TYPE = AuthForm.operationType

const getAbstractImage = (type = OPERATION_TYPE.signIn) => ({
	[OPERATION_TYPE.signIn]: Abstract1,
	[OPERATION_TYPE.signUp]: Abstract2,
	[OPERATION_TYPE.forgotPwd]: Abstract3,
	[OPERATION_TYPE.resetPwd]: Abstract4,
}[type])

export const AuthenticationLayout = ({ title, shortDescription, operationType, children}) => {
	const AbstractSrc = getAbstractImage(operationType)

	return (
		<div className={styles.container}>
			<div className={styles.abstract}>
				<img src={AbstractSrc} alt="Abstract Images" className={styles.absImage} />
			</div>
			<div className={styles.content}>
				<p className={styles.heading}>{title}</p>
				<p className={styles.caption}>{shortDescription}</p>
				{children}
			</div>
		</div>
	)
}
