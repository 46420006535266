import { doc, getDoc } from 'firebase/firestore'
import { db } from './config'
import { FIRESTORE_COLLECTIONS } from '../constants/filestore-collections'

export const getUserSnapshot = async uid => {
	const docRef = doc(db, FIRESTORE_COLLECTIONS.USERS, uid)
	const snapshot = await getDoc(docRef)

	if (snapshot.exists()) {
		return {
			data: snapshot.data(),
			docRef,
		}
	}

	return {
		data: null,
		docRef
	}
}
