import React  from 'react';
import { NavLink } from 'react-router-dom';

import styles from './info-block.module.scss';

export const InfoBlock = ({ label, data, type }) => {

	return (
		<div className={styles.container}>
			<p className={styles.label}>
				{label}
			</p>
			{Object.keys(data).map(key => type === 'text' ? (
				<span key={key} className={styles.text}>{data[key]}</span>
			) : (
				<NavLink key={key} to={key}>
					<span className={styles.text}>{data[key]}</span>
				</NavLink>
			))}
		</div>
	);
};
